.custom_tab > .nav-item:not(:first-child) {
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 2px !important;
  margin-right: 2px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.custom_tab > .nav-item.active:not(:first-child) {
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 2px !important;
  margin-right: 2px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #fff !important;
  background-color: #2c7be5 !important;
  border-color: transparent transparent #2c7be5 !important;
}

.custom_tab > .nav-item {
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 2px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.custom_tab > .nav-item.active {
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 2px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #fff !important;
  background-color: #2c7be5 !important;
  border-color: transparent transparent #2c7be5 !important;
}

.custom_tab {
  height: 60px;
  flex-direction: column;
  overflow-x: scroll !important;
  overflow-y: none;
}

.bs-popover-bottom {
  max-width: 100% !important;
  width: 500px !important;
  margin-top: 20px;
}

.custom_dropdown {
  margin-left: 1%;
  margin-right: 4%;
  margin-top: 12px;
}

.custom_top_div {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1000px) {
  .custom_tab {
    display: none;
  }
}

@media (min-width: 1000px) {
  .custom_top_div {
    display: none;
  }
}

::-webkit-scrollbar {
  height: 5px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: grey;
}

.custom-table table {
  border: 1px solid #dcdcdc !important;
}

.custom-table th {
  border: 1px solid #e8e8e8 !important;
  background-color: black !important;
  color: white !important;
}

@media print {
  .recharts-wrapper,
  .recharts-surface {
    width: calc(100%) !important;
  }
}

.question-block {
  display: flex;
  flex-wrap: wrap;
}

.question-block .question-item {
  width: 40px !important;
  height: 40px !important;
  background: #fbfcfe;
  border-radius: 6px;
  display: flex;
  margin-top: 10px;
  margin-right: 20px;
  margin-bottom: 10px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
}

#submit {
  margin-right: 5%;
}

@media (max-width: 768px) {
  #submit {
    margin-right: 15px;
  }
}

#react-mathjax-preview-result {
  font-family: serif;
  font-size: 19px;
  line-height: 38px;
}
iframe {
  display: none;
}

@media (min-width: 320px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  .solution-navigation {
    margin-bottom: 20px;
  }

  .select-question {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media (min-width: 480px) {
  /* smartphones, Android phones, landscape iPhone */
  .solution-navigation {
    margin-bottom: 20px;
  }

  .select-question {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.left-icon {
  min-width: 80px;
}

.right-icon {
  min-width: 80px;
}

.select-question {
  min-width: 150px !important;
  margin-right: 5px;
}

.carousel-control-next {
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6334908963585435) 100%
  );
}

.carousel-control-prev {
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.pointer {
  cursor: pointer;
}

.subheader {
  font-weight: 500;
  margin-bottom: 10px;
}

.no-border {
  border: none !important;
}

.active-link {
  color: black !important;
}

.performance-title {
  font-size: 35px;
  font-weight: 500;
}

.card-subtext {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 125%;
  text-decoration-line: underline;
  color: #12263f;
}

.semicolon::before {
  content: ":";
}

.cursor-pointer {
  cursor: pointer;
}

.fill-the-blank {
  max-width: 175px;
}

.ftb-p {
  line-height: 2;
}

.numpad {
  /* border: 1px solid #cccccc; */
  background-color: #F4F4F4;
  max-width: 150px;
  padding: 10px;
}

.numpad-input {
  width: 150px;
  border: 1px solid #666666;
  border-radius: 3px;
  background-color: #fefefe;
  padding: 0px;
  font-size: 1em;
}

.num-button button {
  width: 100%;
}

.numpad-main-btn {
  border: 1px solid #666666;
  border-radius: 5px;
  background-color: #dedbe4;
  font-weight: bold;
  padding: 5px 5px;
  opacity: 0.9;
}
.numpad-main-btn:hover {
  background-color: yellowgreen;
}

.numpad-key-btn {
  border: 1px solid black;
  border-radius: 5px;
  background-color: #eeeeee;
  font-weight: bold;
  padding: 5px 5px;
  opacity: 0.9;
}

.numpad-key-btn:hover {
  background-color: rgb(145, 224, 230);
}

#react-mathjax-preview {
  display: inline-block;
}

.font-weight-bold {
  font-weight: 700;
}
